.custom-table table {
  width: 100%;
}

// Give each of the four columns equal width
.custom-table th,
.custom-table td {
  width: 25%;
}

.custom-table th,
.custom-table td {
  white-space: nowrap;
}

// align the text in all but the first column to the right
.custom-table td:nth-child(n + 2) {
  text-align: right;
}

p:has(img) {
  display: inline; /* Optional: Verhindert, dass das <p>-Tag selbst sichtbar ist */
}

.split {
  display: flex;
}

.inline-text {
  display: inline-block;
  margin: 0 1em 1em 0;
  width: 45%;
}

// for mobile devices even in landscape mode
@media screen and (max-width: 800px) {
  .split {
    flex-direction: column;
  }

  .inline-text {
    width: 100%;
  }
}
