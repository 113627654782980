/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/inter.400.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../fonts/Inter.700.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

html {
  height: 100%;
}

body {
  font-family: 'Inter', -apple-system, 'avenir next', avenir, roboto, noto, ubuntu, 'helvetica neue', helvetica,
    sans-serif;
  font-size: 1rem;
  line-height: 2;
  margin: 0;
  min-height: 100%;
}
pre,
code {
  font-family: 'Roboto Mono', 'Courier New', monospace;
  font-size: 0.9rem;
}

h2,
h3,
h4,
h5 {
  margin-top: 1.5em;
}

hr {
  margin: 1em 0;
}

hr.page-break {
  text-align: center;
  border: 0;

  &:before {
    content: '-----';
  }
  &:after {
    content: attr(data-content) '-----';
  }
}

p {
  margin: 1em 0;
}

li {
  margin: 0.4em 0;
}

.w {
  max-width: 1280px;
  margin: 0 auto;
  padding: 4em 2em;
}

table,
th,
td {
  width: 100%;
  border: thin solid black;
  border-collapse: collapse;
  padding: 0.4em;
}

div.highlighter-rouge pre code,
pre code.highlighter-rouge {
  display: block;
  overflow-x: auto;
  padding: 1em;
}

blockquote {
  font-style: italic;
  border: thin solid black;
  padding: 1em;

  p {
    margin: 0;
  }
}

img {
  max-width: 100%;
  display: block;
  margin: 0 auto;
}
@mixin light-appearance {
  html,
  body {
    --bg: #fff;
    --bg-secondary: #f3f4f6;
    --headings: #1e293b;
    --text: #374151;
    --text-secondary: #6b7280;
    --links: #6366f1;
    --highlight: #ffecb2; // light yellow
    --code-text: #9d174d;
    --share-text: #999;
  }
}
// -------------- THEME SWITCHER -------------- //
@mixin dark-appearance {
  html,
  body {
    --headings: #74c0fc;
    --links: #91a7ff;
    --highlight: #41c7c7;
    --bg: #1f242a;
    --bg-secondary: #323945;
    --text: #adb5bd;
    --text-secondary: #9ca3af;
    --code-text: #91a7ff;
    --share-text: #c4c4c4;
  }
}

html[data-theme='dark'] {
  @include dark-appearance;
}
html[data-theme='light'] {
  @include light-appearance;
}

@media (prefers-color-scheme: dark) {
  body[data-theme='auto'] {
    @include dark-appearance;
  }
}
@media (prefers-color-scheme: light) {
  body[data-theme='auto'] {
    @include light-appearance;
  }
}

// -------------------------------------------- //

html,
body {
  background-color: var(--bg);
  color: var(--text);
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--headings);
}
p,
strong,
b,
em,
small,
li,
hr,
table,
figcaption {
  color: var(--text);
}
.highlight,
pre code,
blockquote {
  border-radius: 0.5em;
}
blockquote {
  background-color: var(--bg-secondary);
  border: 1px var(--border) solid;
}
a {
  color: var(--links);
}
*:target {
  background-color: var(--bg-secondary);
}

html.transition,
html.transition *,
html.transition *:before,
html.transition *:after {
  transition: all 250ms !important;
  transition-delay: 0 !important;
}

.theme-toggle {
  color: var(--text);
  background-color: transparent;
  padding: 4px;
  cursor: pointer;
  margin: 1em;
  position: fixed;
  right: 0;
  top: 0;
  border: 2px transparent solid;
  outline: none;
}

.theme-toggle:hover {
  color: var(--links);
  outline: none;
}
.theme-toggle:focus {
  outline: none;
}
.dashed {
  border-top: 1px var(--text) dashed;
  margin: 0.5em 0;
}
mark {
  padding: 0.4em;
  background-color: var(--highlight);
  font-size: 0.6em;
  letter-spacing: 1px;
}

.post-date {
  color: var(--text-secondary);
  margin-top: 1rem;
  font-size: 0.7em;
  font-family: 'Roboto Mono', 'Courier New', monospace;
}
.home-date {
  font-family: 'Roboto Mono', 'Courier New', monospace;
}
.post-list-item a {
  text-decoration: none;
}
.text-bold {
  font-weight: bold;
}
.text-upcase {
  text-transform: uppercase;
  letter-spacing: 1px;
}
p code,
li code {
  background-color: var(--bg-secondary);
  padding: 0.2rem;
  color: var(--code-text);
  font-weight: bold;
}
.post-title {
  margin-bottom: -1.5rem;
}
.project-title {
  margin-bottom: 0.8rem;
}
.credits {
  font-size: 0.8em;
  color: var(--text);
  margin: 8em auto -4em auto;
  text-align: center;
  a {
    color: var(--text);
    text-decoration: none;
    font-weight: bold;
  }
  a:hover {
    color: var(--links);
  }
}
.tag {
  font-family: 'Roboto Mono', 'Courier New', monospace;
  color: var(--text-secondary);
  padding: 0.4em 1em;
  margin-right: 0.5em;
  border-radius: 1em;
  background-color: var(--bg-secondary);
  font-size: 0.7em;
  font-weight: bold;
  text-transform: lowercase;
  //border: 1px solid var(--text-secondary);
}

@import './additions.scss';
